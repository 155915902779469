<template>
  <div>
    <Contact/>
    <section class="slice slice-lg pb-5">
      <div class="container container-pdf">
        <h3>申请测试资格</h3>
        <!--        <iframe src="form.html" class="form-calss"></iframe>-->
        <FormMy/>
      </div>
    </section>
    <section class="slice slice-lg pb-5" style="background: #50b5ff29;">
      <div class="container container-pdf">
        <h3>功能演示视频</h3>
        <div class="row">
          <div class="col-lg-6 mb-5 mb-lg-0">
            <video id="video1" controls style="margin-top:15px;" width="420">
                            <source src="../img/MP4/产品结构管理-EBOM管理.mp4" type="video/mp4" />
            </video>
          </div>
          <div class="col-lg-6 mb-5 mb-lg-0">
            <video id="video1" controls style="margin-top:15px;" width="420">
                            <source src="../img/MP4/项目计划.mp4" type="video/mp4" />
            </video>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 mb-5 mb-lg-0">
            <video id="video1" controls style="margin-top:15px;" width="420">
                            <source src="../img/MP4/工艺管理.mp4" type="video/mp4" />
            </video>
          </div>
          <div class="col-lg-6 mb-5 mb-lg-0">
            <video id="video1" controls style="margin-top:15px;" width="420">
                            <source src="../img/MP4/Cadence集成.mp4" type="video/mp4" />
            </video>
          </div>
        </div>

      </div>
    </section>
    <!--悬浮按钮-->
    <Footer/>
  </div>
</template>
<script>
import FormMy from '@/views/components/FormMy.vue'
import Contact from '@/views/components/Contact.vue'

export default {
  components: {Contact, FormMy},

}
</script>
<style scoped>
.aboutimg {
  width: 216px;
  height: 80px;
}

.aboutimg:hover {
  transition: 1s;
  transform: scale(1.1);
}

.flex_jc {
  display: flex;
  justify-content: center;
  text-align: center;
}

.container-pdf {
  text-align: center;
}

/* 悬浮样式 */
.white_content {
  position: fixed;
  right: 10px;
  bottom: 200px;
  z-index: 999;
  background: none;
}

.white_content-1 {
  background: #50b5ff;
  padding: 10px 20px;
  border-radius: 30%;
  text-align: center;
  color: #171347;
}

.form-calss {
  width: 50%;
  height: 500px;
}

@media (max-width: 900px) {
  .form-calss {
    width: 100%;
    height: 500px;
  }
}
</style>
