<template>

  <div class="container">
    <div class="row">
      <div class="col-lg-6 mb-5 mb-lg-0 center">
        <el-form id="form" ref="form" :model="form" label-width="100px">
          <el-form-item :rules="[{required: true,message: '请填写公司名称'}]" label="公司名称:" prop="company">
            <el-input v-model="form.company"></el-input>
          </el-form-item>
          <el-form-item :rules="[{required: true,message: '请填写姓名'}]" label="姓名:" prop="username">
            <el-input v-model="form.username"></el-input>
          </el-form-item>
          <el-form-item :rules="[{required: true,message: '请填写联系电话'}]" label="联系电话:" prop="telephone">
            <el-input v-model="form.telephone"></el-input>
          </el-form-item>
          <el-form-item
              :rules="[{required: true,message: '请填写邮箱',trigger: 'blur'},{ type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }]"
              label="邮箱:"
              prop="email">
            <el-input v-model="form.email"></el-input>
          </el-form-item>
          <el-form-item label="产品:" prop="product">
            <el-checkbox-group v-model="form.product">
              <el-checkbox label="SPLM" name="product"></el-checkbox>
              <el-checkbox label="SPMS" name="product"></el-checkbox>
              <el-checkbox label="SMPM" name="product"></el-checkbox>
              <el-checkbox label="SCAXLink" name="product"></el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="需求:" prop="require_mark">
            <el-input v-model="form.require_mark" :autosize="{ minRows: 4, maxRows: 8}" type="textarea"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm">提交</el-button>
            <el-button @click="resetForm">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import {post} from '@/plugins/axios'

export default {
  data() {
    return {
      form: {
        company: null,
        username: null,
        telephone: null,
        email: null,
        product: [],
        require_mark: null,
      }
    }
  },
  methods: {
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          // eslint-disable-next-line no-unused-vars
          post('/feedback', this.form).then(response => {
            this.$message.success('提交成功')
          })
        } else {
          // console.log('error submit!!')
          return false
        }
      })
    },
    resetForm() {
      this.$refs.form.resetFields()
    },
  },

}
</script>
